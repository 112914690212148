import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
// import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AOS from 'aos';
import CardsDevice from '../../components/cardsDevice.vue';
import FrontDevice from '../../assets/mynone front.webp';
import PerspectiveDevice from '../../assets/Device_rev_1.webp';
import AssemblyImage from '../../assets/Device_rev_4.webp';
export default {
  name: 'Solution',
  components: {
    // ScrollParallax,
    CardsDevice: CardsDevice
  },
  data: function data() {
    return {
      image: FrontDevice,
      image2: PerspectiveDevice,
      image3: AssemblyImage
    };
  },
  methods: {
    ActiveIndicator: function ActiveIndicator() {
      var indicators = document.querySelectorAll('a.indicator');
      var sections = document.querySelectorAll('.blue');

      var resetCurrentActiveIndicator = function resetCurrentActiveIndicator() {
        var activeIndicator = document.querySelector('.active');
        activeIndicator.classList.remove('active');
      };

      indicators.forEach(function (indicator) {
        // eslint-disable-next-line
        indicator.addEventListener('click', function (event) {
          event.preventDefault();
          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
          });
          resetCurrentActiveIndicator();
          this.classList.add('active');
        });
      });

      var onSectionLeavesViewport = function onSectionLeavesViewport(section) {
        var observer = new IntersectionObserver(function (entries) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              resetCurrentActiveIndicator();
              var element = entry.target;
              var indicator = document.querySelector("a[href='#".concat(element.id, "']"));
              indicator.classList.add('active');
            }
          });
        }, {
          root: null,
          rootMargin: '0px',
          threshold: 0.75
        });
        observer.observe(section);
      };

      sections.forEach(onSectionLeavesViewport);
    }
  },
  mounted: function mounted() {
    this.ActiveIndicator();
    AOS.init();
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.defaults({
      // Defaults are used by all ScrollTriggers
      toggleActions: 'play none play none' // 'restart pause resume pause' Scoll effect Forward, Leave, Back, Back Leave

    });
    ScrollTrigger.matchMedia({
      // eslint-disable-next-line
      // desktop
      '(min-width: 900px)': function minWidth900px() {
        var timelineHeader = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOM',
            // Custom label to the marker
            trigger: '#first',
            // What element triggers the scroll
            scrub: true,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top',
            // Start at top of Trigger and at the top of the viewport
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            // Pin the element true or false
            onEnter: function onEnter() {
              timelineHeader.timeScale(5.5);
              console.log('enter');
            },
            onEnterBack: function onEnterBack() {
              timelineHeader.timeScale(5.5);
            },
            markers: false
          }
        });
        var timelineHeader2 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOM',
            // Custom label to the marker
            trigger: '#second',
            // What element triggers the scroll
            scrub: false,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top',
            // Start at top of Trigger and at the top of the viewport
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            snap: {
              snapTo: 3 / 5,
              duration: 0
            },
            markers: false // Pin the element true or false

          }
        });
        var timelineHeader3 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOM',
            // Custom label to the marker
            trigger: '#third',
            // What element triggers the scroll
            scrub: false,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top',
            // Start at top of Trigger and at the top of the viewport
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            snap: {
              snapTo: 2 / 4,
              duration: 0
            },
            markers: false // Pin the element true or false

          }
        });
        var timelineHeader4 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOM',
            // Custom label to the marker
            trigger: '#fourth',
            // What element triggers the scroll
            scrub: true,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top top',
            // Start at top of Trigger and at the top of the viewport
            end: '+=100%',
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            markers: false // Pin the element true or false

          }
        });
        var timelineHeader5 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOM',
            // Custom label to the marker
            trigger: '#fifth',
            // What element triggers the scroll
            scrub: true,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top',
            // Start at top of Trigger and at the top of the viewport
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            markers: false // Pin the element true or false

          }
        });
        timelineHeader.to('.detailText', {
          y: '-400%',
          x: '15%'
        }, 'sameTime').fromTo('.hero', {
          duration: 0.1,
          scaleX: 1.2,
          scaleY: 1.2,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, {
          duration: 0.1,
          scaleX: 0.6,
          scaleY: 0.6,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 0
        }, '<').fromTo('.device1', {
          duration: 0.1,
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center'
        }, {
          duration: 0.1,
          y: '-70%',
          x: '40%',
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center'
        }, '<');
        timelineHeader2.to('.infoText', {
          duration: 0.2,
          y: '-400%',
          x: '100%',
          overwrite: 'auto'
        }, 'sameTime').fromTo('.device2', {
          duration: 0.2,
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 0
        }, {
          duration: 0.2,
          x: '-40%',
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, '<');
        timelineHeader3.fromTo('.device3', {
          scaleX: 0.5,
          scaleY: 0.5,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, {
          x: '0%',
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, '<').to('.featureText', {
          y: '-400%',
          x: '30%'
        }, 'sameTime').to('.featureText2', {
          y: '-500%',
          x: '-30%'
        }, 'sameTime');
        var canvas = document.getElementById('canvas');
        var context = canvas.getContext('2d');
        canvas.width = 2000;
        canvas.height = 2370;
        var frameCount = 16;

        var currentFrame = function currentFrame(index) {
          return "https://res.cloudinary.com/duu8rthhr/image/upload/v1627985150/sequence/".concat((index + 1).toString().padStart(4, 'adf'), ".png");
        };

        var images = [];
        var airpods = {
          frame: 0
        };

        function render() {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(images[airpods.frame], 0, 0);
        }

        for (var i = 0; i < frameCount; i += 1) {
          var img = new Image();
          img.src = currentFrame(i);
          images.push(img);
        }

        gsap.to(airpods, {
          frame: frameCount - 1,
          snap: 'frame',
          scrollTrigger: {
            trigger: '.bg4',
            start: 'top top',
            // Start at top of Trigger and at the top of the viewport
            end: '+=100%',
            scrub: true,
            pin: true,
            markers: false
          },
          onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate

        });
        images[0].onload = render;
        timelineHeader4.to('.tooltipText', {
          y: '0%',
          x: '0%'
        }, 'sametime');
        timelineHeader5.to('.hero2', {
          duration: 1,
          scaleX: 1.2,
          scaleY: 1.2,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, {
          duration: 0.1,
          scaleX: 0.6,
          scaleY: 0.6,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 0
        }, '<');
      },
      // mobile
      '(max-width: 899px)': function maxWidth899px() {
        var timelineHeaderMobile = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOMMobile',
            // Custom label to the marker
            trigger: '#first',
            // What element triggers the scroll
            scrub: true,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top',
            // Start at top of Trigger and at the top of the viewport
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            // Pin the element true or false
            snap: {
              snapTo: 2 / 5,
              duration: 0
            }
          }
        });
        var timelineHeaderMobile2 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOMMobile',
            // Custom label to the marker
            trigger: '#second',
            // What element triggers the scroll
            scrub: false,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top',
            // Start at top of Trigger and at the top of the viewport
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            // Pin the element true or false
            snap: {
              snapTo: 2 / 5,
              duration: 0
            }
          }
        });
        var timelineHeaderMobile3 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOMMobile',
            // Custom label to the marker
            trigger: '#third',
            // What element triggers the scroll
            scrub: false,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top',
            // Start at top of Trigger and at the top of the viewport
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            // Pin the element true or false
            snap: {
              snapTo: 2 / 5,
              duration: 0
            }
          }
        });
        var timelineHeaderMobile4 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOM',
            // Custom label to the marker
            trigger: '#fourth',
            // What element triggers the scroll
            scrub: true,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top top',
            // Start at top of Trigger and at the top of the viewport
            end: '+=100% 0px',
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            markers: false // Pin the element true or false

          }
        });
        var timelineHeaderMobile5 = gsap.timeline({
          scrollTrigger: {
            id: 'ZOOM',
            // Custom label to the marker
            trigger: '#fifth',
            // What element triggers the scroll
            scrub: true,
            // Add a small delay of scrolling and animation. `true` is direct
            start: 'top top',
            // Start at top of Trigger and at the top of the viewport
            end: '+=100% 0px',
            // The element is 500px hight and end 50px from the top of the viewport
            pin: true,
            markers: false // Pin the element true or false

          }
        });
        timelineHeaderMobile.to('.detailText', {
          y: -300,
          x: 0
        }, 'sameTime').fromTo('.hero', {
          duration: 1,
          scaleX: 1.2,
          scaleY: 1.2,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, {
          duration: 0.1,
          scaleX: 0.6,
          scaleY: 0.6,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 0
        }, '<').to('.device1', {
          duration: 0.2,
          y: -400,
          x: 0,
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center'
        }, '<');
        timelineHeaderMobile2.to('.infoText', {
          y: '10%',
          x: '0%'
        }, 'sameTime').to('.device2', {
          duration: 0,
          y: '20%',
          x: '-40%',
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, '<');
        timelineHeaderMobile3.fromTo('.device3', {
          scaleX: 0.5,
          scaleY: 0.5,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, {
          x: '0%',
          scaleX: 1.0,
          scaleY: 1.0,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, '<').to('.featureText', {
          y: '100%',
          x: '30%'
        }, 'sameTime').to('.featureText2', {
          y: '250%',
          x: '15%'
        }, 'sameTime');
        var canvas = document.getElementById('canvas');
        var context = canvas.getContext('2d');
        canvas.width = 2000;
        canvas.height = 2370;
        var frameCount = 16;

        var currentFrame = function currentFrame(index) {
          return "https://res.cloudinary.com/duu8rthhr/image/upload/v1627985150/sequence/".concat((index + 1).toString().padStart(4, 'adf'), ".png");
        };

        var images = [];
        var airpods = {
          frame: 0
        };

        function render() {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(images[airpods.frame], 0, 0);
        }

        for (var i = 0; i < frameCount; i += 1) {
          var img = new Image();
          img.src = currentFrame(i);
          images.push(img);
        }

        gsap.to(airpods, {
          frame: frameCount - 1,
          snap: 'frame',
          scrollTrigger: {
            trigger: '.bg4',
            start: 'top top',
            // Start at top of Trigger and at the top of the viewport
            end: '+=100%',
            scrub: true,
            pin: true,
            markers: false
          },
          onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate

        });
        images[0].onload = render;
        timelineHeaderMobile4.to('.tooltipText', {
          y: 0,
          x: 0
        }, 'sametime');
        timelineHeaderMobile5.to('.hero2', {
          duration: 1,
          scaleX: 1.2,
          scaleY: 1.2,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 1
        }, {
          duration: 0.1,
          scaleX: 0.6,
          scaleY: 0.6,
          transformOrigin: '50% 50% 0',
          backgroundPosition: 'center',
          opacity: 0
        }, '<');
      }
    });
  }
};